<!--
Shanoir NG - Import, manage and share neuroimaging data
Copyright (C) 2009-2019 Inria - https://www.inria.fr/
Contact us on https://project.inria.fr/shanoir/

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

You should have received a copy of the GNU General Public License
along with this program. If not, see https://www.gnu.org/licenses/gpl-3.0.html
-->

<div *ngIf="toggleForm" #formContainer class="AnestheticIngredientFormComponent content-component">
	<form *ngIf="form"  [formGroup]="form" class="max-content" novalidate>
		<table class="layout">
			<tr><td colspan="2">
				<div class="header command-zone" *ngIf="createAIMode" i18n="Create anestheticingredient|Title">Add ingredient</div>
				<div class="header command-zone" *ngIf="!createAIMode" i18n="Edit anestheticingredient|Title">Edit ingredient</div>
			</td></tr>
			<tr>
				<td>
					<fieldset>
					<ol>
						<li class="required">
							<label i18n="Edit anestheticingredient|Name label">Name</label>  
							<span class="right-col" [ngSwitch]="mode">
								<ng-template [ngSwitchCase]="'view'"> 
										{{ingredient?.name?.value}}
								</ng-template>
								<ng-template ngSwitchDefault>
									<select id="name" formControlName="name" [(ngModel)]="ingredient.name">
										<option *ngFor="let name of names" [ngValue]="name">{{name.value}}</option>
									</select>
									<button *ngIf="keycloakService.isUserAdmin()" i18n="Edit anestheticingredient|Add a new ingredient name" (click)="goToRefPage('anesthetic','ingredient')">
										<i class="fas fa-plus-square"></i>
										new ingredient name
									</button>
								<label *ngIf="hasError('name', ['required'])" class="form-validation-alert" i18n="Edit anestheticIngredient|NameRequiredError label">Name is required!</label>
								</ng-template>
							</span>
						</li>
						<li class="required">
							<label i18n="Edit anestheticingredient|Concentration label">Concentration</label> 
							<span class="right-col" [ngSwitch]="mode">
								<ng-template [ngSwitchCase]="'view'"> 
										{{ingredient?.concentration}}
								</ng-template>
								<ng-template ngSwitchDefault>
									<input type="text" id="concentration" formControlName="concentration" [(ngModel)]="ingredient.concentration"/>
								</ng-template>
							</span>
						</li>
						<li class="required">
							<label i18n="Edit anestheticingredient|ConcentrationUnit label">Concentration Unit</label> 
							<span class="right-col" [ngSwitch]="mode">
								<ng-template [ngSwitchCase]="'view'"> 
										{{ingredient?.concentration_unit?.value}}
								</ng-template>
								<ng-template ngSwitchDefault>
									<select id="concentration_unit" required formControlName="concentration_unit" [(ngModel)]="ingredient.concentration_unit">
										<option *ngFor="let unit of units" [ngValue]="unit">{{unit.value}}</option>
									</select>
									<button *ngIf="keycloakService.isUserAdmin()" i18n="Edit anestheticingredient|Add a new concentration unit" (click)="goToRefPage('unit','concentration')">
										<i class="fas fa-plus-square"></i>
										new concentration unit
									</button>
								</ng-template>
							</span>
						</li>
					</ol>
					</fieldset>
				</td>				
			</tr>
		</table>
		<div class="footer command-zone">
			<button type="button" i18n="Edit anestheticingredient|CancelButton label" class="Button right-icon" (click)="cancelIngredient()">Cancel<i class="fas fa-angle-left"></i></button>
			<button type="button" *ngIf="canUpdateIngredient()" (click)="updateIngredient()" i18n="Edit anestheticingredient|SaveButton label" class="Button right-icon" [disabled]="!form.valid">Update<i class="far fa-save"></i></button>
			<button type="button" *ngIf="canAddIngredient()" i18n="Edit anestheticingredient|CreateButton label" (click)="addIngredient()" class="Button right-icon" [disabled]="!form.valid">Save<i class="far fa-save"></i></button>
			
		</div>
    </form>
</div>