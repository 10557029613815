<!--
Shanoir NG - Import, manage and share neuroimaging data
Copyright (C) 2009-2019 Inria - https://www.inria.fr/
Contact us on https://project.inria.fr/shanoir/

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

You should have received a copy of the GNU General Public License
along with this program. If not, see https://www.gnu.org/licenses/gpl-3.0.html
-->

<div #formContainer class="content-component detail" [@preventInitialChildAnimations]>
	<form *ngIf="form" [formGroup]="form" class="max-content" novalidate [class.disabled]="footerState.loading">
		<div class="layout" [ngClass]="{'left': mode=='view'}">
			<form-footer
				[state]="footerState"
				(save)="save()"
                (delete) = "delete()"
				(edit)="goToEdit()"
				(cancel)="goToView()"
				(back)="goBack()"
			>
			<button  *ngIf="mode == 'view' && hasDownloadRight" class="right-icon dl-button" type="button" (click)="download()" [disabled]="downloadState.isActive()">Download<i class="fas fa-download"></i></button>
			</form-footer>
			<span [ngSwitch]="mode">
				<ng-template [ngSwitchCase]="'view'">
					<h2 class="header command-zone"i18n="View subject|Title@@subjectDetailViewTitle">Details on subject</h2>
				</ng-template>
				<ng-template [ngSwitchCase]="'edit'">
					<h2 class="header command-zone"i18n="Edit subject|Title@@subjectDetailEditTitle">Edit subject</h2>
				</ng-template>
				<ng-template [ngSwitchCase]="'create'">
					<h2 class="header command-zone"i18n="Create subject|Title@@subjectDetailCreateTitle">Create subject</h2>
				</ng-template>
			</span>
			<fieldset>
				<legend *ngIf="mode !== 'view'" i18n="Subject detail|Name label@@subjectDetailGeneral">General</legend>
				<ol>
					<li>
						<label i18n="Subject detail|Subject Imaged object category label@@SubjectDetailSubjectImagedObjectCategory">Imaged object category</label>
						<span class="right-col" [ngSwitch]="mode">
							<ng-template [ngSwitchCase]="'view'">
								{{subject.imagedObjectCategory}}
							</ng-template>
							<ng-template ngSwitchDefault>
								<select-box formControlName="imagedObjectCategory" [(ngModel)]="subject.imagedObjectCategory" [options]="catOptions">
								</select-box>
								<label *ngIf="hasError('imagedObjectCategory', ['required'])" class="form-validation-alert" i18n="Subject detail|ImagedObjectCategory required error@@subjectDetailImagedObjectCategoryRequiredError">Imaged object category is required!</label>
							</ng-template>
						</span>
					</li>
				</ol>
				<ol>
					<div *ngIf="humanSelected()" [@slideDown]="humanSelected()">
						<li *ngIf="mode == 'create' && humanSelected() && this.importMode != 'EEG'">
							<label i18n="Subject detail|Subject is already anonymized label@@SubjectDetailSubjectIsAlreadyAnonymized">Is the subject already anonymized?</label>
							<span class="right-col">
								<input type="radio" formControlName="isAlreadyAnonymized" [(ngModel)]="isAlreadyAnonymized" (ngModelChange)="toggleAnonymised()" [value]="true" [checked]="isAlreadyAnonymized == true"
								/>Yes
								<input type="radio" formControlName="isAlreadyAnonymized" [(ngModel)]="isAlreadyAnonymized" (ngModelChange)="toggleAnonymised()" [value]="false" [checked]="'isAlreadyAnonymized'?'isAlreadyAnonymized == false' :'true' "
								/>No
							</span>
						</li>
						<li class="info" *ngIf="!isAlreadyAnonymized && mode == 'create'" [@slideDown]="!isAlreadyAnonymized && mode == 'create'">
							<span class="icon"><i class="fas fa-info-circle"></i></span>A subject global identifier will be automatically generated using the first name, the last name and the birth date of the subject.
							This can help you to identify a subject that has already been inserted into the database.
							<br/>To ensure anonymity, the first name and the last name will not be saved.
							<br/>Note that the birth date will be set to January 1st of the birth year for the same reason.
						</li>
						<li *ngIf="!isAlreadyAnonymized && mode == 'create' && importMode != 'EEG'">
							<label i18n="Subject detail|First Name label@@SubjectDetailSubjectFirstName" [class.required-label]="subject.imagedObjectCategory=='LIVING_HUMAN_BEING'">First name</label>
							<span class="right-col">
								<input *ngIf="importMode != 'DICOM'" type="text" id="firstName" [(ngModel)]="firstName" formControlName="firstName" />
                                <div *ngIf="importMode == 'DICOM'"> {{ firstName }} </div>
								<label *ngIf="hasError('firstName', ['required'])" class="form-validation-alert" i18n="Subject detail|FirstName required error@@subjectDetailFirstNameRequiredError">First name is required!</label>
								<label *ngIf="hasError('firstName', ['minlength', 'maxlength'])" class="form-validation-alert" i18n="Subject detail|Name Length error@@subjectDetailNameLengthError">Length must be between 2 and 64!</label>
							</span>
						</li>
						<li *ngIf="!isAlreadyAnonymized && mode == 'create' && importMode != 'EEG'">
							<label i18n="Subject detail|Last Name label@@SubjectDetailSubjectLastName" [class.required-label]="subject.imagedObjectCategory=='LIVING_HUMAN_BEING'">Last name</label>
							<span class="right-col">
                                <input *ngIf="importMode != 'DICOM'" type="text" id="lastName" [(ngModel)]="lastName" formControlName="lastName" />
                                <div *ngIf="importMode == 'DICOM'"> {{ lastName }} </div>
								<label *ngIf="hasError('lastName', ['required'])" class="form-validation-alert" i18n="Subject detail|LastName required error@@subjectDetailLastNameRequiredError">Last name is required!</label>
								<label *ngIf="hasError('lastName', ['minlength', 'maxlength'])" class="form-validation-alert" i18n="Subject detail|Name Length error@@subjectDetailNameLengthError">Length must be between 2 and 64!</label>
							</span>
						</li>
					</div>
                    <li>
                        <label i18n="Subject detail|Name label@@subjectDetailName">Common name</label>
                        <span class="right-col" [ngSwitch]="mode">
                            <ng-template [ngSwitchCase]="'view'">
                                {{subject.name}}
                            </ng-template>
                            <ng-template [ngSwitchCase]="'edit'">
                                {{subject.name}}
                            </ng-template>
                            <ng-template ngSwitchDefault>
                                <input type="text" id="name" formControlName="name" [(ngModel)]="subject.name"/>
                                <label *ngIf="hasError('name', ['required'])" class="form-validation-alert" i18n="Subject detail|Name required error@@subjectDetailNameRequiredError">Common Name is required !</label>
                                <label *ngIf="hasError('name', ['minlength', 'maxlength'])" class="form-validation-alert" i18n="Subject detail|Name Length error@@subjectDetailNameLengthError">Length must be between 2 and 64 !</label>
                                <label *ngIf="hasError('name', ['unique'])" class="form-validation-alert" i18n="Subject detail|Name unique error@@subjectDetailNameUniqueError">This name is already taken !</label>
                                <label *ngIf="hasError('name', ['subjectNamePrefix'])" class="form-validation-alert" i18n="Subject detail|Name unique error@@subjectDetailNameUniqueError">The name should not be equal to the study prefix.</label>
                                <label *ngIf="hasError('name', ['pattern'])" class="form-validation-alert" i18n="Subject detail|Name chars error@@subjectDetailNameCharsError">Forbidden characters</label>
                                <label *ngIf="hasError('name', ['notEmptyValidator'])" class="form-validation-alert" i18n="Subject detail|Name empty error@@subjectDetailNameCharsError">Name must contain more than one character</label>
                            </ng-template>
                        </span>
					</li>
					<li class="info" *ngIf="subjectNamePrefix && !subject.name?.startsWith(subjectNamePrefix) && mode != 'view'" @slideDown>
						<span class="icon"><i class="fas fa-info-circle"></i></span>This study is configured to use prefixes for subject names, here the prefix is <b>{{subjectNamePrefix}}</b>.
						However the prefix is optional and if you know what you are doing please just ignore this message.
					</li>
					<div *ngIf="humanSelected()" [@slideDown]="humanSelected()">
						<li *ngIf="mode == 'create' || mode == 'view'">
							<label i18n="Subject detail|Birth date label@@subjectDetailBirthDate" [class.required-label]="subject.imagedObjectCategory=='LIVING_HUMAN_BEING'">Birth date</label>
							<span [ngSwitch]="mode" class="right-col">
								<ng-template [ngSwitchCase]="'view'">
									{{subject.birthDate | date: 'dd/MM/yyyy'}}
								</ng-template>
								<ng-template [ngSwitchCase]="'create'">
									<datepicker [(ngModel)]="subject.birthDate" formControlName="birthDate"></datepicker>
									<label *ngIf="hasError('birthDate', ['format'])" class="form-validation-alert" i18n="Subject detail|Date valid error@@dateValidError">Date should be valid! Date format: dd/mm/yyyy</label>
									<label *ngIf="hasError('birthDate', ['required'])" class="form-validation-alert" i18n="Subject detail|BirthDate required error@@subjectDetailBirthDateRequiredError">You need to enter a birth date for a living humain being!</label>
								</ng-template>
							</span>
						</li>
						<li>
							<label i18n="Subject detail|Sex label@@subjectDetailSex">Sex</label>
							<span class="right-col" [ngSwitch]="mode">
								<ng-template [ngSwitchCase]="'view'">
									{{subject.sex}}
								</ng-template>
								<ng-template [ngSwitchCase]="'edit'">
									{{subject.sex}}
								</ng-template>
								<ng-template ngSwitchDefault>
									<select-box formControlName="sex" [(ngModel)]="subject.sex" [options]="genderOptions">
									</select-box>
								</ng-template>
							</span>
						</li>
						<li>
							<label i18n="Subject detail|Manual Hemispheric Dominance label@@subjectDetailManualHemisphericDominance">Manual Hemispheric Dominance</label>
							<span class="right-col" [ngSwitch]="mode">
								<ng-template [ngSwitchCase]="'view'">
									{{subject.manualHemisphericDominance}}
								</ng-template>
								<ng-template ngSwitchDefault>
									<select-box formControlName="manualHemisphericDominance" [(ngModel)]="subject.manualHemisphericDominance" [optionArr]="['Left', 'Right']">
									</select-box>
								</ng-template>
							</span>
						</li>
						<li>
							<label i18n="Subject detail|Language Hemispheric Dominance label@@subjectDetailLanguageHemisphericDominance">Language Hemispheric Dominance</label>
							<span class="right-col" [ngSwitch]="mode">
								<ng-template [ngSwitchCase]="'view'">
									{{subject.languageHemisphericDominance}}
								</ng-template>
								<ng-template ngSwitchDefault>
									<select-box formControlName="languageHemisphericDominance" [(ngModel)]="subject.languageHemisphericDominance" [optionArr]="['Left','Right']">
									</select-box>
								</ng-template>
							</span>
						</li>
					</div>
				</ol>
				<ol *ngIf="!humanSelected()" [@slideDown]="!humanSelected()">
					<li *ngIf="mode == 'create'">
						<label class="date-label" i18n="Subject detail|Birth date label@@subjectDetailBirthDate">Date of creation</label>
						<span class="right-col">
							<datepicker [(ngModel)]="subject.birthDate" formControlName="birthDate"></datepicker>
							<label *ngIf="hasError('birthDate', ['format'])" class="form-validation-alert" i18n="Subject detail|Date valid error@@dateValidError">Date should be valid! Date format: dd/mm/yyyy</label>
						</span>
					</li>
				</ol>
			</fieldset>
			<fieldset *ngIf="mode !== 'view' && !forceStudy">
				<subject-study-list [subject]="subject" [selectableList]="studies" [(ngModel)]="subject.subjectStudyList" formControlName="subjectStudyList" [mode]="mode"></subject-study-list>
                <label *ngIf="hasError('subjectStudyList', ['required'])" class="form-validation-alert" i18n="Subject detail|Subject StudyList required error@@subjectDetailSubjectStudyListRequiredError">Study is required!</label>
			</fieldset>
			<fieldset *ngIf="mode !== 'view' && forceStudy">
				<ol>
					<li>
						<label>Study</label>
						<span class="right-col">{{forceStudy.name}}</span>
					</li>
				</ol>
			</fieldset>
		</div>
	</form>
</div>
