<!--
Shanoir NG - Import, manage and share neuroimaging data
Copyright (C) 2009-2019 Inria - https://www.inria.fr/
Contact us on https://project.inria.fr/shanoir/

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

You should have received a copy of the GNU General Public License
along with this program. If not, see https://www.gnu.org/licenses/gpl-3.0.html
-->

<ng-template #pager>
    <div class="pager" *ngIf="maxPage > 1">
        <span [class.clickable]="currentPage.number > 1" [class.disabled]="currentPage.number <= 1" (click)="currentPage.number > 1 ? goToPage(currentPage.number - 1) : null"><i class="fas fa-angle-left"></i></span>
        <span>{{currentPage.number}}</span>
        <span [class.clickable]="currentPage.number < maxPage" class="clickable" [class.disabled]="currentPage.number >= maxPage" (click)="currentPage.number < maxPage ? goToPage(currentPage.number + 1) : null"><i class="fas fa-angle-right"></i></span>
    </div>
</ng-template>

<div class="title">
    <div class="clickable" (click)="toggle()">
        <span class="left-icon">
            <i *ngIf="awesome" [class]="awesome"></i>
            {{label}}
        </span>
        <div *ngIf="!open" class="colapse"><i class="fas fa-chevron-down"></i></div>
        <div *ngIf="open" class="colapse"><i class="fas fa-chevron-up"></i></div>
    </div>
</div>
<div *ngIf="open && loaded" @slideDown class="content">
    <ul *ngIf="loaded && hasChecked" class="selections">
        <span *ngFor="let item of selectedFacets" class="selection" [title]="item.value">
            <span class="remove-selected" (click)="item.checked = false; onCheckChange(item);"><i class="fas fa-times"></i></span>
            {{item.value}}
            <!-- <span class="facet-count" *ngIf="item.valueCount > 0">{{item.valueCount}}</span> -->
        </span>
        <span *ngIf="this.selectedFacets.length > 0" title="Reset" (click)="clearSelection()" class="clear-selection left-icon clickable"><i class="fas fa-snowplow"></i></span>
    </ul>
    <div>
        <div class="filter-frame">
            <input [(ngModel)]="filterText" (ngModelChange)="onFilterChange()" class="filter" placeholder="filter..." />
            <span class="left-icon clickable clear" (click)="clearFilter()"><i class="fas fa-times"></i>clear</span>
        </div>
    </div>
    <div class="sort-mode">
        <i title="Sort by index" [class.disabled]="sortMode == 'COUNT'" class="fa-solid fa-arrow-down-a-z" (click)="sortMode == 'COUNT' ? toggleSortMode() : null"></i>
        <i title="Sort by count" [class.disabled]="sortMode == 'INDEX'" class="fa-solid fa-arrow-down-9-1" (click)="sortMode == 'INDEX' ? toggleSortMode() : null"></i>
    </div>
    <ng-container *ngIf="displayedFacets && displayedFacets.length > 0">
        <ng-template [ngTemplateOutlet]="pager"></ng-template>
        <ul *ngIf="loaded" class="min-list">
            <ng-container *ngFor="let item of displayedFacets">
                <li *ngIf="!item.hidden">
                    <checkbox [(ngModel)]="item.checked" (ngModelChange)="onCheckChange(item)"></checkbox>
                    <span class="facet" [title]="item.value">{{item.value}}</span><span class="facet-count" *ngIf="item.valueCount > 0">{{item.valueCount}}</span>
                </li>
            </ng-container>
        </ul>
        <ng-template [ngTemplateOutlet]="pager"></ng-template>
    </ng-container>
    <div class="no-result" *ngIf="!displayedFacets || displayedFacets.length == 0">No compatible search terms</div>
</div>