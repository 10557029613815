<!--
Shanoir NG - Import, manage and share neuroimaging data
Copyright (C) 2009-2019 Inria - https://www.inria.fr/
Contact us on https://project.inria.fr/shanoir/

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

You should have received a copy of the GNU General Public License
along with this program. If not, see https://www.gnu.org/licenses/gpl-3.0.html
-->

<span>if,&nbsp;</span>
<span class="view" *ngIf="mode == 'view'">
    <span class="cardinality-type" *ngIf="condition.cardinality == -1">for every</span>
    <span class="cardinality-type" *ngIf="condition.cardinality == 0">for no</span>
    <span class="cardinality-type" *ngIf="condition.cardinality > 0">for at least</span>
    <span class="cardinality" *ngIf="condition.cardinality > 0">{{condition.cardinality}}</span>
    <span>dataset<ng-container *ngIf="condition.cardinality > 1">s</ng-container> in the exam, </span>
    <span class="var" *ngIf="condition.scope == 'StudyCardDICOMConditionOnDatasets'" title="{{condition.dicomTag | dicomTagLabel}}">{{condition.dicomTag?.label ? condition.dicomTag.label : (condition.dicomTag?.code | dicomTagLabel)}}</span>
    <span class="var" *ngIf="condition.scope != 'StudyCardDICOMConditionOnDatasets'">{{fieldLabel}}</span>
    <span class="method">
        <i *ngIf="condition.operation == 'EQUALS'" class="fa-solid fa-equals"></i>
        <i *ngIf="condition.operation == 'NOT_EQUALS'" class="fa-solid fa-not-equal"></i>
        <i *ngIf="condition.operation == 'SMALLER_THAN'" class="fa-solid fa-less-than"></i>
        <i *ngIf="condition.operation == 'BIGGER_THAN'" class="fa-solid fa-greater-than"></i>
        <ng-container *ngIf="!(['EQUALS', 'NOT_EQUALS', 'SMALLER_THAN', 'BIGGER_THAN'].includes(condition.operation))">
            {{condition.operation}}
        </ng-container>
    </span>
    <span class="multi-value" *ngIf="condition.type == 'string'">
        <div class="value" *ngFor="let value of condition.values; let i=index;">
            <span *ngIf="i > 0" class="or">or</span>
            "{{value}}"
        </div>
    </span>
    <span class="multi-value" *ngIf="condition.type == 'Coil'">
        <div class="value" *ngFor="let coil of condition.values; let i=index;">
            <span *ngIf="i > 0" class="or">or</span>
            {{coil.name || coil.id}}
        </div>
    </span>
</span>
<ng-container *ngIf="mode != 'view' && init">
    <select-box
        class="cardinality-type auto-width"
        [(ngModel)]="cardinalityType"
        (userChange)="onCardinalityTypeChange()"
        [clear]="false"
        placeholder="select cardinality"
        [search]="false"
        [options]="[{label: 'for no', value: 'NONE'}, {label: 'for every', value: 'ALL'}, {label: 'for at least', value: 'AT_LEAST'}]">
    </select-box>
    <input type="number"
        class="cardinality auto-width"
        *ngIf="cardinalityType == 'AT_LEAST'"
        [(ngModel)]="condition.cardinality"
        [class.error]="cardinalityError"
        min="1" 
        [style.width]="(condition.cardinality ? ((condition.cardinality+'').length + 3) : '4') + 'ch'">
    <span>dataset<ng-container *ngIf="condition.cardinality > 1">s</ng-container> in the exam, </span>
    <select-box 
        class="type auto-width"
        [ngModel]="condition.scope" 
        (userChange)="onConditionTypeChange($event)" 
        [options]="conditionTypeOptions"
        placeholder="select conditition type" 
        [clear]="false">
    </select-box>
    <select-box 
            *ngIf="condition.scope == 'StudyCardDICOMConditionOnDatasets'"
            class="var auto-width" 
            [class.error]="tagError"
            [(ngModel)]="condition.dicomTag"
            (userChange)="onDicomFieldChange($event)" 
            [options]="tagOptions" 
            placeholder="select dicom tag" 
            (focusout)="tagTouched = true"
            [clear]="false">
    </select-box>
    <select-box 
            *ngIf="condition.scope != 'StudyCardDICOMConditionOnDatasets'"
            class="var auto-width" 
            [class.error]="shanoirFieldError"
            [(ngModel)]="condition.shanoirField" 
            (userChange)="onFieldChange($event)" 
            [options]="fieldOptions" 
            placeholder="select shanoir metadata field" 
            (focusout)="shanoirFieldTouched = true"
            [clear]="false">
    </select-box>
    <select-box 
            [readOnly]="condition.shanoirField && shanoirFieldOptions?.length > 0"
            class="method auto-width"
            [class.error]="operationError"
            [(ngModel)]="condition.operation" 
            (userChange)="onOperationChange()" 
            [options]="operations" 
            placeholder="select operation" 
            (focusout)="operationTouched = true"
            [clear]="false">
    </select-box>
    <ng-container *ngIf="condition?.operation != 'PRESENT' && condition?.operation != 'ABSENT'"> 
        <form [formGroup]="form">
            <span *ngIf="condition.scope == 'StudyCardDICOMConditionOnDatasets' || !(shanoirFieldOptions?.length > 0)" formArrayName="values" class="multi-value edit">
                <span *ngFor="let value of condition.values; let i  = index; trackBy: trackByFn" class="one-value">
                    <auto-ajust-input 
                            [formControlName]="i"
                            class="value"
                            [(ngModel)]="condition.values[i]"
                            (change)="onConditionChange()"
                            placeholder="enter value"
                            (focusout)="valueTouched = true">
                    </auto-ajust-input>
                    <span *ngIf="condition.values.length > 1" class="delete-value" (click)="onTextValueRemove(i)"><i class="far fa-times-circle"></i></span>
                </span>
                <div *ngIf="!(condition.values[condition.values.length - 1]?.toString().length == 0)" class="add-cond-value-frame">
                    <span class="add-cond-value" (click)="onTextValueAdd()">
                        <i class="fa-solid fa-circle-plus"></i>Add a possible value
                    </span>
                </div>
            </span>
            <span *ngIf="condition.scope != 'StudyCardDICOMConditionOnDatasets' && (shanoirFieldOptions?.length > 0)" formArrayName="values" class="multi-value edit">
                <select-box 
                        *ngFor="let value of condition.values; let i  = index"
                        [formControlName]="i"
                        class="value auto-width one-value" 
                        [(ngModel)]="condition.values[i]" 
                        (userChange)="onConditionValueChange($event, i)" 
                        (selectOption)="onConditionOptionSelect($event)" 
                        (onUserClear)="onConditionOptionUnselect($event)" 
                        [options]="shanoirFieldOptions" 
                        placeholder="select value" 
                        (focusout)="valueTouched = true">
                </select-box>
                <div *ngIf="condition.values[condition.values.length - 1] && condition.values.length < shanoirFieldOptions.length" class="add-cond-value-frame">
                    <span class="add-cond-value" (click)="condition.values?.push(null)">
                        <i class="fa-solid fa-plus"></i>Add a possible value
                    </span>
                </div>
            </span>
        </form>
    </ng-container>
</ng-container>
<span *ngIf="mode != 'view'" class="delete" (click)="delete.emit()"><i class="far fa-times-circle"></i></span>
