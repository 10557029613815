<!--
Shanoir NG - Import, manage and share neuroimaging data
Copyright (C) 2009-2019 Inria - https://www.inria.fr/
Contact us on https://project.inria.fr/shanoir/

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

You should have received a copy of the GNU General Public License
along with this program. If not, see https://www.gnu.org/licenses/gpl-3.0.html
-->
<node
        *ngIf="node"
        [class.selected]="isAdmin && (this.menuOpened || treeService.isSelected(node.id, 'user'))"
        [label]="node.label"
        awesome="far fa-user"
        [(opened)]="node.opened"
        (labelClick)="menuOpened = isAdmin && withMenu && !menuOpened"
        [route]="node.route"
        [hasChildren]="hasChildren()">

    <dropdown-menu [(openInput)]="menuOpened" *ngIf="isAdmin">
        <a [routerLink]="this.detailsPath + this.node.id" class="open-new-tab">
            <menu-item label="Details..." awesome="fa-regular fa-eye"></menu-item>
        </a>
    </dropdown-menu>

    <ng-container *ngIf="node.open && node.rights && node.rights != $any('UNLOADED')">
            <node
                    *ngFor="let right of node.rights"
                    [label]="right.label"
                    awesome="fas fa-medal"
                    [hasChildren]="false">
            </node>
    </ng-container>
</node>
