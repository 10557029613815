<!--
Shanoir NG - Import, manage and share neuroimaging data
Copyright (C) 2009-2019 Inria - https://www.inria.fr/
Contact us on https://project.inria.fr/shanoir/

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

You should have received a copy of the GNU General Public License
along with this program. If not, see https://www.gnu.org/licenses/gpl-3.0.html
-->


<form [formGroup]="form" novalidate>
    <div class="header command-zone">1. Query PACS</div>
    <fieldset class="step">
        <ol>
            <legend>
                Please fill <strong class="warning">at least one</strong> of the five fields below to query the PACS. Blank is not allowed.<br/>
                If Patient Name, Patient ID or Patient Birth Date is filled, a PatientRoot query is send to the PACS.<br/>
                You can fill Study Description and Study Date in combination with the three patient values above to affine your search.<br/>
                If only Study Description or/and Study Date is filled a StudyRoot query is send to the PACS.
            </legend>
            <p>The maximum number of patients returned is limited to 10, please optimize your query to find relevant results.</p>
            <li>
                <label>Patient Name</label> 
                <span class="right-col">
                    <input type="text" id="patientName" formControlName="patientName" maxlength="64" [(ngModel)]="dicomQuery.patientName" />
                    <label *ngIf="hasError('patientName', ['maxlength'])" [@slideDown]="hasError('patientName', ['maxlength'])" class="form-validation-alert">Length must not exceed 64!</label>
                    <label *ngIf="hasError('patientName', ['pattern'])" class="form-validation-alert">The wildcard character is not allowed</label>
                </span>
                <tool-tip>Enter the exact value, case sensitive, the wildcard character is not allowed. Ex: TITI^TOTO won't match titi'toto</tool-tip>
            </li>
            <li>
                <label>Patient ID</label> 
                <span class="right-col">
                    <input type="text" id="patientID" formControlName="patientID" maxlength="64" [(ngModel)]="dicomQuery.patientID" />
                    <label *ngIf="hasError('patientID', ['maxlength'])" [@slideDown]="hasError('patientID', ['maxlength'])" class="form-validation-alert">Length must not exceed 64!</label>
                    <label *ngIf="hasError('patientID', ['pattern'])" class="form-validation-alert">The wildcard character is not allowed</label>
                </span>
                <tool-tip>Enter the exact value, the wildcard character is not allowed.</tool-tip>
            </li>
            <li>
                <label>Patient Birth Date</label> 
                <span class="right-col">
                    <input type="text" id="patientBirthDate" placeholder="yyyyMMdd" [(ngModel)]="dicomQuery.patientBirthDate" formControlName="patientBirthDate" />
                    <label *ngIf="hasError('patientBirthDate', ['pattern'])" class="form-validation-alert">Date format required by the PACS: yyyyMMdd</label>
                </span>
                <tool-tip>Date format required by the PACS: yyyyMMdd</tool-tip>
            </li>
            <li>
                <label>Study Description</label> 
                <span class="right-col">
                    <input type="text" id="studyDescription" formControlName="studyDescription" maxlength="64" minlength="4" [(ngModel)]="dicomQuery.studyDescription" />
                    <label *ngIf="hasError('studyDescription', ['maxlength', 'minlength'])" [@slideDown]="hasError('studyDescription', ['maxlength', 'minlength'])" class="form-validation-alert">Length must be between 4 and 64!</label>
                </span>
                <tool-tip>The wildcard character is allowed. For instance, you can enter STUDY* to find STUDY^NEURO</tool-tip>
            </li>
            <li>
                <label>Study Date</label> 
                <span class="right-col">
                    <input type="text" id="studyDate" placeholder="yyyyMMdd" [(ngModel)]="dicomQuery.studyDate" formControlName="studyDate" />
                    <label *ngIf="hasError('studyDate', ['pattern'])" class="form-validation-alert">Date format required by the PACS: yyyyMMdd</label>
                </span>
                <tool-tip>Date format required by the PACS: yyyyMMdd</tool-tip>
            </li>
        </ol>
    </fieldset>
    <button class="next" [disabled]="!form.valid" (click)="queryPACS()">Query</button>
</form>
