<!--
Shanoir NG - Import, manage and share neuroimaging data
Copyright (C) 2009-2019 Inria - https://www.inria.fr/
Contact us on https://project.inria.fr/shanoir/

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

You should have received a copy of the GNU General Public License
along with this program. If not, see https://www.gnu.org/licenses/gpl-3.0.html
-->
<node
        *ngIf="node"
        [class.selected]="(showDetails && this.menuOpened) || treeService.isSelected(node.id, 'study')"
        [label]="node.label"
        awesome="fa-regular fa-folder-open"
        [(opened)]="node.opened"
        (labelClick)="menuOpened = withMenu && !menuOpened"
        [route]="node.route"
        [hasChildren]="true"
        [clickable]="showDetails"
        >

    <dropdown-menu [(openInput)]="menuOpened" *ngIf="showDetails && menuOpened && withMenu">
        <a [routerLink]="this.detailsPath + this.node.id" class="open-new-tab">
            <menu-item label="Details..." awesome="fa-regular fa-eye"></menu-item>
        </a>
    </dropdown-menu>

    <node
            *ngIf="node.open"
            class="subjects"
            label="Subjects"
            awesome="fas fa-user-injured"
            [(opened)]="node.subjectsNode.opened"
            (labelClick)="subjectsMenuOpened = !subjectsMenuOpened"
            [hasChildren]="hasDependency(this.node.subjectsNode.subjects)">

        <div class="overmenu">
            <span *ngIf="!(subjectsOrder.field == 'name' && subjectsOrder.way == 'asc')" class="filter" (click)="sortSubjects({field: 'name', way : 'asc'})" title="sort by name ascending">
                <i class="fa-solid fa-arrow-down-a-z"></i>
            </span>
            <span *ngIf="subjectsOrder.field == 'name' && subjectsOrder.way == 'asc'" class="filter" (click)="sortSubjects({field: 'name', way : 'desc'})" title="sort by name descending">
                <i class="fa-solid fa-arrow-down-z-a"></i>
            </span>
            <span *ngIf="!(subjectsOrder.field == 'id' && subjectsOrder.way == 'desc')" class="filter" (click)="sortSubjects({field: 'id', way : 'desc'})" title="sort by id ascending">
                <i class="fa-solid fa-arrow-down-1-9"></i>
            </span>
            <span *ngIf="subjectsOrder.field == 'id' && subjectsOrder.way == 'desc'" class="filter" (click)="sortSubjects({field: 'id', way : 'asc'})" title="sort by id descending">
                <i class="fa-solid fa-arrow-down-9-1"></i>
            </span>
            <span class="filter">
                filter : 
                <input type="text" [(ngModel)]="filter" (ngModelChange)="onFilterChange()"/>
                <span (click)="resetFilter()">&nbsp;x&nbsp;</span>
            </span>
        </div>

        <ng-container *ngIf="node.subjectsNode.opened && node.subjectsNode.subjects && node.subjectsNode.subjects != $any('UNLOADED')">
            <subject-node
                    *ngFor="let subject of filteredNodes || node.subjectsNode.subjects"
                    [input]="subject"
                    [studyId]="node.id"
                    (selectedChange)="selectedChange.emit(node)"
                    [hasBox]="hasBox"
                    [withMenu]="withMenu"
                    [rights]="rights"
                    (nodeInit)="onSubjectNodeInit()" 
            >
            </subject-node>
        </ng-container>

    </node>

    <node
            *ngIf="node.open"
            class="centers"
            label="Centers"
            awesome="fa-regular fa-hospital"
            [(opened)]="node.centersNode.opened"
            [hasChildren]="hasDependency(this.node.centersNode.centers)">

        <ng-container *ngIf="node.centersNode.opened && node.centersNode.centers && node.centersNode.centers != $any('UNLOADED')">
            <center-node
                    *ngFor="let center of node.centersNode.centers"
                    [input]="center"
                    [withMenu]="withMenu">
            </center-node>
        </ng-container>

    </node>

    <node
            *ngIf="node.open"
            class="studycards"
            label="Study Cards"
            awesome="fa-solid fa-shuffle"
            (firstOpen)="loadStudyCards()"
            [(opened)]="node.studyCardsNode.opened"
            [hasChildren]="hasDependency(node.studyCardsNode.cards)"
            [dataLoading]="studyCardsLoading">

        <ng-container *ngIf="node.studyCardsNode.opened && node.studyCardsNode.cards && node.studyCardsNode.cards != $any('UNLOADED')">
            <card-node
                    *ngFor="let studycard of node.studyCardsNode.cards; let i = index"
                    [input]="studycard"
                    (onCardDelete)="onStudyCardDelete(i)"
                    [withMenu]="withMenu"
                    detailsPath="study-card/details"
            >
            </card-node>
        </ng-container>

    </node>

    <node
            *ngIf="node.open"
            class="studycards"
            label="Quality Cards"
            awesome="fa-solid fa-shuffle"
            (firstOpen)="loadQualityCards()"
            [(opened)]="node.qualityCardsNode.opened"
            [hasChildren]="hasDependency(node.qualityCardsNode.cards)"
            [dataLoading]="qualityCardsLoading">

        <ng-container *ngIf="node.qualityCardsNode.opened && node.qualityCardsNode.cards && node.qualityCardsNode.cards != $any('UNLOADED')">
            <card-node
                    *ngFor="let qualitycard of node.qualityCardsNode.cards; let i = index"
                    [input]="qualitycard"
                    (onCardDelete)="onQualityCardDelete(i)"
                    [withMenu]="withMenu"
                    detailsPath="quality-card/details"
            >
            </card-node>
        </ng-container>

    </node>

    <node
            *ngIf="node.open"
            class="members"
            label="Members"
            awesome="far fa-user"
            [(opened)]="node.membersNode.opened"
            [hasChildren]="hasDependency(this.node.membersNode.members)">

        <ng-container *ngIf="node.membersNode.opened && node.membersNode.members && node.membersNode.members != $any('UNLOADED')">
            <member-node
                    *ngFor="let member of node.membersNode.members"
                    [input]="member"
                    [withMenu]="withMenu">
            </member-node>
        </ng-container>

    </node>
</node>
