<!--
Shanoir NG - Import, manage and share neuroimaging data
Copyright (C) 2009-2019 Inria - https://www.inria.fr/
Contact us on https://project.inria.fr/shanoir/

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

You should have received a copy of the GNU General Public License
along with this program. If not, see https://www.gnu.org/licenses/gpl-3.0.html
-->

<div class="overlay">
    <div class="main">
        <div class="header">
            <i class="fas fa-question-circle"></i>
            {{ title }}
        </div>
        <div class="body">
            <p>{{ message }}</p>
            <p>Number of datasets selected for copy : {{ datasetsIds.length }}</p>
        </div>
        <div class="titles">
            <p class="studies">Study selection:</p>
        </div>
        <div class="container">
            <div class="list">
                <div *ngFor="let study of studies" (click)="pickStudy(study)">
                    <div class="studyRow">
                        <div [ngClass]="{'study-default': selectedStudy != study, 'study-change': selectedStudy == study}">{{ study.name }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="status">
            Status:
            <div class="status-message">
                {{ statusMessage }}
            </div>
        </div>
        <div class="footer-position">
            <div class="footer">
                <button type="button" (click)="copy()" [disabled]="!canCopy">Copy</button>
                <button type="button" (click)="close()">Close</button>
            </div>
        </div>
    </div>
</div>
