<!--
Shanoir NG - Import, manage and share neuroimaging data
Copyright (C) 2009-2019 Inria - https://www.inria.fr/
Contact us on https://project.inria.fr/shanoir/

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

You should have received a copy of the GNU General Public License
along with this program. If not, see https://www.gnu.org/licenses/gpl-3.0.html
-->

<span class="clickable" [class.disabled]="currentPage == 1" (click)="currentPage > 1 ? goToPage(currentPage - 1) : null">&#9664;</span>
<span class="wrapper" *ngFor="let p of pagerList">
    <span *ngIf="p==null">...</span>
    <span *ngIf="p!=null" class="clickable" (click)="goToPage(p)" [class.active]="p == currentPage">{{p}}</span>
</span>
<span class="clickable" [class.disabled]="!nbPages || currentPage == nbPages" (click)="currentPage < nbPages ? goToPage(currentPage + 1) : null">&#9654;</span>
