<!--
Shanoir NG - Import, manage and share neuroimaging data
Copyright (C) 2009-2019 Inria - https://www.inria.fr/
Contact us on https://project.inria.fr/shanoir/

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

You should have received a copy of the GNU General Public License
along with this program. If not, see https://www.gnu.org/licenses/gpl-3.0.html
-->

<legend>
    {{legend}}
    <tool-tip>You can choose one or more {{legend | lowercase}}s from the list</tool-tip>
</legend>
<ol>
    <li *ngIf="mode != 'view'">
        <label>Add a {{legend | lowercase}}</label>
        <span class="right-col">
            <select-box [(ngModel)]="selected" [ngModelOptions]="{standalone: true}" placeholder="Please select ..." (onAddClick)="onAdd()" [addDisabled]="!selected" (blur)="onTouch()" [options]="optionList">
            </select-box>
        </span>
    </li>
    <li *ngIf="model && model.length > 0 && columnDefs">
        <shanoir-table #table
                [getPage]="getPage.bind(this)"
                [editMode]="mode != 'view'"
                [columnDefs]="columnDefs"
                [browserSearch]="false"
                [rowRoute]="rowClick.bind(this)"
                (rowEdit)="onChange()">
        </shanoir-table>
    </li>
</ol>
