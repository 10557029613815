<!--
Shanoir NG - Import, manage and share neuroimaging data
Copyright (C) 2009-2019 Inria - https://www.inria.fr/
Contact us on https://project.inria.fr/shanoir/

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

You should have received a copy of the GNU General Public License
along with this program. If not, see https://www.gnu.org/licenses/gpl-3.0.html
-->

<node
        *ngIf="node"
        [class.selected]="this.menuOpened || treeService.isSelected(node.id, 'dataset')"
        [label]="node.label"
        [tags]="node.tags"
        [awesome]="node.awesome"
        [(opened)]="node.opened"
        (labelClick)="toggleMenu()"
        [route]="'/dataset/details/' + node.id"
        [dataLoading]="loading"
        [hasBox]="hasBox"
        [(ngModel)]="node.selected"
        (chkbxChange)="selectedChange.emit()"
        [hasChildren]="hasChildren()"
        [title]="node.title + ' n°' + node.id">

    <dropdown-menu [(openInput)]="menuOpened" *ngIf="menuOpened && withMenu">
        <a [routerLink]="this.detailsPath + this.node.id" class="open-new-tab">
          <menu-item *ngIf="!related" label="Details..." awesome="fa-regular fa-eye"></menu-item>
        </a>
        <menu-item [disabled]="loading" label="Download" awesome="fas fa-download" (click)="download()"></menu-item>
        <menu-item *ngIf="this.node.canDelete"  label="Delete" awesome="fa-regular fa-trash-can" (click)="deleteDataset()"></menu-item>
    </dropdown-menu>

    <node
            *ngIf="node.open && node.inPacs"
            [class.selected]="treeService.isSelected(node.id, 'dicomMetadata')"
            (labelClick)="null"
            [route]="'/dataset/details/dicom/' + node.id"
            label="DICOM metadata"
            awesome="fas fa-file-code"
            [hasChildren]="false">
    </node>

    <ng-container *ngIf="node.open && node.processings && node.processings != $any('UNLOADED')">
        <processing-node
                node
                *ngFor="let processing of node.processings; let i = index"
                [input]="processing"
                (selectedChange)="selectedChange.emit()"
                [hasBox]="hasBox"
                (onProcessingDelete)="onProcessingDelete(i)"
                [withMenu]="withMenu"
        >
        </processing-node>

    </ng-container>
</node>
