<!--
Shanoir NG - Import, manage and share neuroimaging data
Copyright (C) 2009-2019 Inria - https://www.inria.fr/
Contact us on https://project.inria.fr/shanoir/

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

You should have received a copy of the GNU General Public License
along with this program. If not, see https://www.gnu.org/licenses/gpl-3.0.html
-->

<node
        *ngIf="node"
        [label]="node.label"
        [class.selected]="treeService.isSelected(node.id, 'processing')"
        awesome="fas fa-gears"
        [(opened)]="node.opened"
        (labelClick)="toggleMenu()"
        [route]="node.route"
        [hasChildren]="hasChildren()"
        [title]="node.title + ' n°' + node.id">

    <dropdown-menu [(openInput)]="menuOpened" *ngIf="menuOpened && withMenu">
        <menu-item label="Details..." awesome="fa-regular fa-eye" (click)="showProcessingDetails()"></menu-item>
        <menu-item *ngIf="node.canDelete"  label="Delete" awesome="fa-regular fa-trash-can" (click)="deleteProcessing()"></menu-item>
    </dropdown-menu>

    <ng-container *ngIf="node.open && node.datasets && node.datasets != $any('UNLOADED')">
        <simple-dataset-node
                node
                *ngFor="let dataset of node.datasets; let i = index"
                [input]="dataset"
                (onSimpleDatasetDelete)="onSimpleDatasetDelete(i)"
                [withMenu]="withMenu"
        >
        </simple-dataset-node>

    </ng-container>
</node>
