/**
 * Shanoir NG - Import, manage and share neuroimaging data
 * Copyright (C) 2009-2019 Inria - https://www.inria.fr/
 * Contact us on https://project.inria.fr/shanoir/
 * 
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 * 
 * You should have received a copy of the GNU General Public License
 * along with this program. If not, see https://www.gnu.org/licenses/gpl-3.0.html
 */

import { Subject } from "rxjs";

/**
 * For example, helps wait for a resize event, that is triggered multiple time (forming a burst) at each resize, to end.
 */
export class WaitBurstEnd {
    
    private ts: number = 0;

    constructor(private onEnd: () => any, private delayMs: number = 300) {}

    fire() {
        const ts = Date.now();
        if ((ts - this.ts) > this.delayMs) {
            this.ts = ts;
            setTimeout(() => {
                if (this.ts == ts) { // end
                    this.onEnd();
                }
            }, this.delayMs);
        }
    }

}