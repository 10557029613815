<!--
Shanoir NG - Import, manage and share neuroimaging data
Copyright (C) 2009-2019 Inria - https://www.inria.fr/
Contact us on https://project.inria.fr/shanoir/

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

You should have received a copy of the GNU General Public License
along with this program. If not, see https://www.gnu.org/licenses/gpl-3.0.html
-->

<form>
    <h2>Import {{importMode}}</h2>
    <router-outlet *ngIf="hasOneStudy"></router-outlet>
    <div *ngIf="!hasOneStudy">
        <p><strong class="warning"><i class="fas fa-exclamation-triangle"></i>Sorry, you don't have the right to import in any Study for the moment.</strong></p>
        <p>You should contact the administrator of the study you want to import in and ask him/her to add you as a study member with the appropriate import right.</p>
    </div>
</form>