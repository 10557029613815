<!--
Shanoir NG - Import, manage and share neuroimaging data
Copyright (C) 2009-2019 Inria - https://www.inria.fr/
Contact us on https://project.inria.fr/shanoir/

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

You should have received a copy of the GNU General Public License
along with this program. If not, see https://www.gnu.org/licenses/gpl-3.0.html
-->

<div class="overlay">
    <div class="main" [class.error]="mode == 'error'">
        <div class="header">
            <i *ngIf="mode == 'error'" class="fas fa-exclamation-circle"></i>
            <i *ngIf="mode == 'confirm'" class="fas fa-question-circle"></i>
            {{ title }}
        </div>
        <div class="body">
            <p [innerHTML]="message"></p>
            <a *ngIf="link" [href]="link">{{link}}</a>
        </div>
        <div class="footer">
            <button type="button" *ngIf="mode != 'choose'"
                (click)="close(true)">{{buttons && buttons.yes ? buttons.yes : 'OK'}}</button>
            <button type="button" *ngIf="mode == 'choose'"
                (click)="close('yes')">{{buttons?.yes || 'Yes'}}</button>
            <button type="button" *ngIf="mode == 'choose'"
                (click)="close('no')">{{buttons?.no || 'No'}}</button>
            <button type="button" *ngIf="mode == 'confirm' || mode == 'choose'"
                (click)="close(false)">{{buttons && buttons.cancel ? buttons.cancel : 'Cancel'}}</button>
        </div>
    </div>
</div>