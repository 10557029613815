/**
 * Shanoir NG - Import, manage and share neuroimaging data
 * Copyright (C) 2009-2019 Inria - https://www.inria.fr/
 * Contact us on https://project.inria.fr/shanoir/
 * 
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 * 
 * You should have received a copy of the GNU General Public License
 * along with this program. If not, see https://www.gnu.org/licenses/gpl-3.0.html
 */

import { Pipe, PipeTransform } from '@angular/core';
import { DicomTag } from '../../shared/study-card.model';


@Pipe({ name: "dicomTagLabel", pure: false })
export class DicomTagPipe implements PipeTransform {

    transform(tag: DicomTag): string {
        if (tag) {
            if (typeof tag.code != 'number') throw new Error('dicom tag code should be a number');
            let hexStr: string = tag.code.toString(16).padStart(8, '0').toUpperCase();
            return hexStr.substr(0, 4) + ',' + hexStr.substr(4, 4);
            //return tag.label + ' (' + hexStr.substr(0, 4) + ',' + hexStr.substr(4, 4) + ')';
        }
        return '';
    }

}