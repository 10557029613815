/**
 * Shanoir NG - Import, manage and share neuroimaging data
 * Copyright (C) 2009-2019 Inria - https://www.inria.fr/
 * Contact us on https://project.inria.fr/shanoir/
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program. If not, see https://www.gnu.org/licenses/gpl-3.0.html
 */

import { Option } from "../../shared/select/select.component";
import { allOfEnum, capitalsAndUnderscoresToDisplayable } from "../../utils/app.utils";


export enum DatasetType {
  Calibration = 'Calibration',
  Ct = 'Ct',
  Eeg = 'Eeg',
  Meg = 'Meg',
  Mesh = 'Mesh',
  Mr = 'Mr',
  ParameterQuantification = 'ParameterQuantification',
  Pet = 'Pet',
  Registration = 'Registration',
  Segmentation = 'Segmentation',
  Spect = 'Spect',
  Statistical = 'Statistical',
  Template = 'Template',
  BIDS = 'BIDS',
  Measurement = 'Measurement',
  Xa = 'Xa'
} export namespace DatasetType {

  export function all(): Array<DatasetType> {
      return allOfEnum<DatasetType>(DatasetType);
  }

  export function getLabel(type: DatasetType): string {
      return capitalsAndUnderscoresToDisplayable(type);
  }

  export var options: Option<DatasetType>[] = all().map(prop => new Option<DatasetType>(prop, getLabel(prop)));
}
