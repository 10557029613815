<!--
Shanoir NG - Import, manage and share neuroimaging data
Copyright (C) 2009-2019 Inria - https://www.inria.fr/
Contact us on https://project.inria.fr/shanoir/

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

You should have received a copy of the GNU General Public License
along with this program. If not, see https://www.gnu.org/licenses/gpl-3.0.html
-->

<div class="header command-zone">1. Select DICOM archive</div>
	<fieldset class="step dicom">
	    <checkbox [(ngModel)]="multipleExamImport" [disabled]="archiveStatus != 'none'" ></checkbox> 
	    <label> Multiple examination import.</label>
	    <div *ngIf="!multipleExamImport">
	    <p>Please select a DICOM zip archive to import.</p>
	    <p>This archive may contain a DICOMDIR file at its root :</p>
	    <ul>
	        <li>If a DICOMDIR is present, it will be used to import the data (RECOMMENDED).</li>
	        <li>If not, one will be automatically generated from DICOM images <strong>at the root of the archive.</strong></li>
	    </ul>
	    </div>
	    <div *ngIf="multipleExamImport">
	    <p> Multi examination allows a more simple and automatic import, with multiple possible examinations. </p>
	    <p>Select a subject folder with on or multiple examinations sub folders and zip it.</p>
	    <p>The files should be organized like this:</p>
	    <p>SUBJECT_NAME.zip <br/>
	     - [subject-name] <br/>
	     - - [examination-comment] <br/>
	     - - - [series] <br/>
	     - - - - [list of dicom] <br/>
	     </p>
	        <u>1) Subject: </u><br/>
	        The subject will be automaticaly created, based on the subject name, and information found in the dicom.
	        Please note that there should be only ONE subject
	     <p>
	        <u>2) Examination: </u><br/>
	        The examination will be automatically created based on examination comment.<br/>
	        Examination date and other informations will be gathered from informations found in the dicom.
	     </p>
	     <p>
            Please select a study in which the data will be imported, and a default study card if none adapted to the equipment are found.
         </p>
	    <ol>
		    <li>
	        <label class="required-label">Select a research study</label> 
	        <span class="right-col">
	            <select-box 
	                    [(ngModel)]="study" 
	                    (userChange)="onSelectStudy()" 
	                    [viewDisabled]="!study" 
	                    [options]="studyOptions">
	            </select-box>
	        </span>
	        </li>
	        <li>
	            <label class="required-label">Default study card</label> 
	            <span class="right-col">
	                <select-box 
	                        [(ngModel)]="studyCard"
	                        (userChange)="onSelectStudyCard()" 
	                        [options]="studycardOptions">
	                </select-box>
	            </span>
		    </li>
	    </ol>
        </div>
	    <upload-file [disabled]="multipleExamImport && !studyCard" (fileChange)="uploadArchive($event)" [loading]="archiveStatus == 'uploading'" [error]="archiveStatus == 'error'"></upload-file>
	    <div [hidden]="!uploadState?.progress">
	        <progress-bar [progress]="uploadState?.progress" [text]="'Preparing upload'"></progress-bar>
	    </div>
	    <label *ngIf="extensionError==true" class="form-validation-alert" i18n="Import|ExtensionError label">
	        A DICOM zip archive is required!
	    </label>
	    <label *ngIf="dicomDirMissingError==true" class="form-validation-alert" i18n="Import|DicomDirMissingError label">
	        DICOMDIR is missing in .zip file!
	    </label>
	    <label *ngIf="fileTooBigError" class="form-validation-alert" i18n="Import|fileTooBigError label">
	        The uploaded file is too big, it should be less then 5Gb.
	    </label>
	    <label *ngIf="otherErrorMessage" class="form-validation-alert" i18n="Import label">
            {{otherErrorMessage}}
        </label>
	    
	    <p *ngIf="modality && !multipleExamImport" class="modality">
	        The modality of the dataset(s) that you are importing is {{modality}}
	    </p>
	   <p *ngIf="multipleExamImport && archiveStatus=='uploaded'" class="modality">
            The import successfully started. Please check "Jobs" tab to follow its progress.
        </p>
	</fieldset>
<button *ngIf="!multipleExamImport" class="next" [disabled]="!valid" (click)="next()">Next</button>
