<!--
    Shanoir NG - Import, manage and share neuroimaging data
    Copyright (C) 2009-2019 Inria - https://www.inria.fr/
    Contact us on https://project.inria.fr/shanoir/

    This program is free software: you can redistribute it and/or modify
    it under the terms of the GNU General Public License as published by
    the Free Software Foundation, either version 3 of the License, or
    (at your option) any later version.

    You should have received a copy of the GNU General Public License
    along with this program. If not, see https://www.gnu.org/licenses/gpl-3.0.html
    -->

<header></header>
<div class="main-offline">
  <div class="extension-request">
      <form *ngIf="extensionRequestInfo" [formGroup]="extensionRequestForm" class="EditUserContent max-content" novalidate>
      <table class="layout">
          <tr>
              <td colspan="1">
                  <h2 class="header command-zone"i18n="Extension request|Title@@ExtensionRequestTitle">Extension request</h2>
              </td>
          </tr>
          <tr>
              <td>
                  <fieldset>
                      <ol>
                          <li class="required">
                              <label i18n="Extension request|Email label@@ExtensionRequestMotivation" class="required-label">Email adress associated to the account.</label>
                              <span class="right-col">
                              <input type="text" autosize id="email" formControlName="email" [(ngModel)]="extensionRequestInfo.email"/>
                              </span>
                          </li>
                          <li class="required">
                              <label i18n="Extension request|Expiration date label@@ExtensionRequestExpirationDate" class="required-label">New expiration Date</label>
                              <span class="right-col">
                              <datepicker [(ngModel)]="extensionRequestInfo.extensionDate" formControlName="extensionDate"></datepicker>
                              </span>
                          </li>
                          <li class="required">
                              <label i18n="Extension request|Motivation label@@ExtensionRequestMotivation" class="required-label">Why do you want to extend your account?</label>
                              <span class="right-col">
                              <textarea autosize id="extensionMotivation" formControlName="extensionMotivation" [(ngModel)]="extensionRequestInfo.extensionMotivation"></textarea>
                              </span>
                          </li>
                      </ol>
                  </fieldset>
              </td>
          </tr>
          <tr>
              <td colspan="2">
                  <div *ngIf="!requestSent" class="footer command-zone">
                      <button i18n="Edit user|Cancel button label@@cancelButton" class="Button" (click)="cancelExtensionRequest()">Cancel</button>
                      <button type="submit" i18n="Extension request|Request button label@@ExtensionRequestButton" (click)="extensionRequest()" class="Button" [disabled]="!isEditUserFormValid()">Request an extension</button>
                  </div>
              </td>
          </tr>
      </table>
      </form>
      <form class="EditUserContent max-content content" novalidate class="EditUserComponent">
          <fieldset>
              <label *ngIf="!errorMessage && requestSent" i18n="Account request|Request sent@@accountRequestRequestSent">An email has been sent to the administrator who will validate your request.<br/>You will receive an email once your account extended.</label>
              <label *ngIf="errorMessage" i18n="Account request|Request sent@@accountRequestRequestSent"> {{errorMessage}}</label>
          </fieldset>
          <div class="footer command-zone" *ngIf="requestSent">
              <button i18n="Account request|Ok button label@@okButton" class="Button" (click)="cancelExtensionRequest()">OK</button>
          </div>
      </form>
  </div>
</div>
