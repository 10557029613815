<!--
Shanoir NG - Import, manage and share neuroimaging data
Copyright (C) 2009-2019 Inria - https://www.inria.fr/
Contact us on https://project.inria.fr/shanoir/

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

You should have received a copy of the GNU General Public License
along with this program. If not, see https://www.gnu.org/licenses/gpl-3.0.html
-->

<div #formContainer *ngIf="toggleForm" class="RefFormComponent content-component">
	<form *ngIf="form"  [formGroup]="form" class="max-content" novalidate>
		<table class="layout">
			<tr><td colspan="2">
				<span class="right-col" [ngSwitch]="mode">
					<ng-template [ngSwitchCase]="'view'">
						<div class="header command-zone" i18n="View therapy|Title">View therapy details</div>
					</ng-template>
					<ng-template ngSwitchDefault>
						<div *ngIf="!createSTMode" class="header command-zone" i18n="Edit therapy|Title">Edit therapy</div>
						<div *ngIf="createSTMode"  class="header command-zone" i18n="Create therapy|Title">Add therapy</div>
					</ng-template>
				</span>
			</td></tr>
			<tr>
				<td>
					<fieldset>
					<ol>
						<li class="required">
							<label i18n="Edit subjectTherapy|Therapy label">Therapy</label>
							<span class="right-col" [ngSwitch]="mode">
								<ng-template [ngSwitchCase]="'view'">
										{{subjectTherapy?.therapy?.name}}
								</ng-template>
								<ng-template ngSwitchDefault>
									<select id="therapy" formControlName="therapy" [(ngModel)]="subjectTherapy.therapy">
										<option *ngFor="let therapy of therapies" [ngValue]="therapy">{{therapy.name}}</option>
									</select>
									<button *ngIf="keycloakService.isUserAdmin() " i18n="Edit subjectTherapy|Add a new therapy" (click)="goToAddTherapy()">
										<i class="fas fa-plus-square"></i>
										new therapy
									</button>
									<label *ngIf="hasError('therapy', ['required'])" [@slideDown]="hasError('therapy', ['required'])" class="form-validation-alert" i18n="Edit subjectTherapy|Therapy required error@@subjectTherapyDetailTherapyRequiredError">Therapy is required!</label>
								</ng-template>
							</span>
						</li>
						<div *ngIf="displayDoseFrequency()">
							<li class="required">
								<label i18n="Edit subjectTherapy|Dose label">Dose</label>
								<span class="right-col" [ngSwitch]="mode">
									<ng-template [ngSwitchCase]="'view'">
											{{subjectTherapy?.dose}}
									</ng-template>
									<ng-template ngSwitchDefault>
										<input type="number" id="dose" formControlName="dose" [(ngModel)]="subjectTherapy.dose"/>
									</ng-template>
								</span>
							</li>
							<li class="required">
								<label i18n="Edit subjectTherapy|DoseUnit label">Dose Unit</label>
								<span class="right-col" [ngSwitch]="mode">
									<ng-template [ngSwitchCase]="'view'">
											{{subjectTherapy?.dose_unit?.value}}
									</ng-template>
									<ng-template ngSwitchDefault>
										<select id="dose_unit" formControlName="dose_unit" [(ngModel)]="subjectTherapy.dose_unit">
											<option *ngFor="let unit of units" [ngValue]="unit">{{unit.value}}</option>
										</select>
									</ng-template>
								</span>
							</li>
                            <li class="required">
                                <label i18n="Edit subjectTherapy|Molecule label">Molecule</label>
                                <span class="right-col" [ngSwitch]="mode">
                                    <ng-template [ngSwitchCase]="'view'">
                                            {{subjectTherapy?.molecule}}
                                    </ng-template>
                                    <ng-template ngSwitchDefault>
                                        <input type="text" id="molecule" formControlName="molecule" [(ngModel)]="subjectTherapy.molecule"/>
                                    </ng-template>
                                </span>
                            </li>
							<li class="required">
								<label i18n="Edit subjectTherapy|Frequency label">Frequency</label>
								<span class="right-col" [ngSwitch]="mode">
									<ng-template [ngSwitchCase]="'view'">
											{{enumUtils.getEnumValue(frequencies,subjectTherapy.frequency)}}
									</ng-template>
									<ng-template ngSwitchDefault>
										<select id="frequency" formControlName="frequency" [(ngModel)]="subjectTherapy.frequency">
											<option *ngFor="let frequency of frequencies" [value]="frequency.key">{{frequency.value}}</option>
										</select>
									</ng-template>
								</span>
							</li>
						</div>
						<li>
							<label i18n="Edit subjectTherapy|startDate label">Start date</label>
							<span class="right-col" [ngSwitch]="mode">
								<ng-template [ngSwitchCase]="'view'">
										{{subjectTherapy?.startDate}}
								</ng-template>
								<ng-template ngSwitchDefault>
									<datepicker id="subjectTherapyStartDate"  [(ngModel)]="subjectTherapy.startDate" formControlName="startDate" ></datepicker>
									<label *ngIf="hasError('startDate', ['format'])" class="form-validation-alert" i18n="SubjectTherapy detail|Date valid error@@dateValidError">Start Date should be valid ! Date format: dd/mm/yyyy</label>
								</ng-template>
							</span>
						</li>
						<li>
							<label i18n="Edit subjectTherapy|endDate label">End date</label>
							<span class="right-col" [ngSwitch]="mode">
								<ng-template [ngSwitchCase]="'view'">
										{{subjectTherapy?.startDate}}
								</ng-template>
								<ng-template ngSwitchDefault>
									<datepicker id="subjectTherapyEndDate"  [(ngModel)]="subjectTherapy.endDate" formControlName="endDate" ></datepicker>
									<label *ngIf="hasError('endDate', ['format'])" class="form-validation-alert" i18n="SubjectTherapy detail|Date valid error@@dateValidError">End Date should be valid ! Date format: dd/mm/yyyy</label>
								</ng-template>
							</span>
						</li>
					</ol>
					</fieldset>
				</td>
			</tr>

		</table>

		<div class="footer command-zone">
			<button type="button" i18n="Edit subjectTherapy|CancelButton label" class="Button right-icon" (click)="cancelTherapy()">Cancel</button>
			<button type="button" *ngIf="canUpdateTherapy()" (click)="updateTherapy()" i18n="Edit subjectTherapy|SaveButton label" class="Button right-icon" [disabled]="!form.valid">Update<i class="far fa-save"></i></button>
			<button type="button" *ngIf="canAddTherapy()" i18n="Edit subjectTherapy|CreateButton label" (click)="addTherapy()" class="Button right-icon" [disabled]="!form.valid">Save<i class="far fa-save"></i></button>

		</div>
    </form>
</div>
