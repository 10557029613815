<!--
Shanoir NG - Import, manage and share neuroimaging data
Copyright (C) 2009-2019 Inria - https://www.inria.fr/
Contact us on https://project.inria.fr/shanoir/

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

You should have received a copy of the GNU General Public License
along with this program. If not, see https://www.gnu.org/licenses/gpl-3.0.html
-->
<div class="cell">
    <form #window class="window" [formGroup]="form" *ngIf="form">
        <h2 class="header">Download Datasets</h2>
        <div class="left-icon msg">
            <i class="fa-solid fa-circle-exclamation"></i>
            <b>Limited functionalities with current browser : </b>
            Shanoir provides a way to download unlimited quantities of datasets to your computer<!-- with convenient options to organize the directories structure-->. Unfortunately your browser does not allow this advanced download functionality.
            See the list of compatible browsers :
            <a href="https://developer.mozilla.org/en-US/docs/Web/API/Window/showDirectoryPicker#browser_compatibility" target="_blank">https://developer.mozilla.org/en-US/docs/Web/API/Window/showDirectoryPicker#browser_compatibility</a>
        </div>
        <div *ngIf="loading">
            <i *ngIf="loading" class="fas fa-cog fa-spin"></i> Retrieving download informations...
        </div>
        <fieldset class="body" disabled="loading">            <ol>
                <li *ngIf="hasDicom">
                    <label>Dataset files format</label>
                    <span class="right-col">
                        <select-box formControlName="format" [options]="formatOptions"></select-box>
                    </span>
                </li>
                <li *ngIf="hasDicom && form.get('format').value == 'nii'">
                    <label>Nifti converter</label>
                    <span class="right-col">
                    <select-box formControlName="converter" [options]="niftiConverters"></select-box>
                </span>
                </li>
            </ol>
        </fieldset>
        <div class="footer">
            <button type="button" type="button" (click)="downloadNow()" class="alt left-icon" [disabled]="form.dirty && !form.valid">
                <i class="fas fa-download"></i>Download zip
            </button>
            <button type="button" (click)="cancel()">Cancel</button>
        </div>
    </form>
</div>
