<!--
Shanoir NG - Import, manage and share neuroimaging data
Copyright (C) 2009-2019 Inria - https://www.inria.fr/
Contact us on https://project.inria.fr/shanoir/

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

You should have received a copy of the GNU General Public License
along with this program. If not, see https://www.gnu.org/licenses/gpl-3.0.html
-->

<div class="input-frame">
    <input type="text" placeholder="search in Shanoir ..." [(ngModel)]="searchText" (change)="onChangeSearch()" (keyup)="onType.emit()" [class.error]="syntaxError" [class.expert]="expertMode"/>
    <span class="left-icon clickable clear" (click)="clear(); this.onChangeSearch();"><i class="fas fa-times"></i>clear</span>
</div>
<div class="syntax-error" *ngIf="syntaxError">syntax error</div>