<!--
Shanoir NG - Import, manage and share neuroimaging data
Copyright (C) 2009-2019 Inria - https://www.inria.fr/
Contact us on https://project.inria.fr/shanoir/

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

You should have received a copy of the GNU General Public License
along with this program. If not, see https://www.gnu.org/licenses/gpl-3.0.html
-->

<span *ngFor="let option of selectedOptions; let i = index"
        class="left-icon block"
        [class.dark]="getFontColor(option.backgroundColor)"
        [style.background-color]="option.backgroundColor">
    <i class="fa-solid fa-xmark remove" (click)="onRemoveOption(option, i)"></i>
    <span class="label">{{option.label}}</span>
</span>

<span class="select-wrapper">
    <span class="open-icon"><i class="fa-solid fa-plus"></i></span>
    <select 
            *ngIf="!readOnly"
            [disabled]="disabled"
            [ngModel]="null"
            (ngModelChange)="onSelectOption($event)">
        <option *ngFor="let option of options"
                [class.disabled]="option.disabled"
                [style.color]="option.color"
                [style.background-color]="option.backgroundColor"
                [ngValue]="option.value"
                [disabled]="option.disabled">
            {{pipe ? pipe.transform(option.value) : option.label}}
        </option>
    </select>
</span>