<!--
Shanoir NG - Import, manage and share neuroimaging data
Copyright (C) 2009-2019 Inria - https://www.inria.fr/
Contact us on https://project.inria.fr/shanoir/

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

You should have received a copy of the GNU General Public License
along with this program. If not, see https://www.gnu.org/licenses/gpl-3.0.html
-->

<fieldset>
	<legend>Pet protocol</legend>
	<ol>
		<li>
			<label>Attenuation correction method</label>
			<span class="right-col">{{protocol.attenuationCorrectionMethod}}</span>
		</li>
		<li>
			<label>Convolution kernel</label>
			<span class="right-col">{{protocol.convolutionKernel}}</span>
		</li>
		<li>
			<label>Decay correction</label>
			<span class="right-col">{{protocol.decayCorrection}}</span>
		</li>
		<li>
			<label>Dimension X</label>
			<span class="right-col">{{protocol.dimensionX}}</span>
		</li>
		<li>
			<label>Dimension Y</label>
			<span class="right-col">{{protocol.dimensionY}}</span>
		</li>
		<li>
			<label>Energy window lower limit ({{getUnit("KEV")}})</label>
			<span class="right-col">{{protocol.energyWindowLowerLimit}}</span>
		</li>
		<li>
			<label>Energy window upper limit ({{getUnit("KEV")}})</label>
			<span class="right-col">{{protocol.energyWindowUpperLimit}}</span>
		</li>
		<li>
			<label>Number of iterations</label>
			<span class="right-col">{{protocol.numberOfIterations}}</span>
		</li>
		<li>
			<label>Number of slices</label>
			<span class="right-col">{{protocol.numberOfSlices}}</span>
		</li>
		<li>
			<label>Number of subsets</label>
			<span class="right-col">{{protocol.numberOfSubsets}}</span>
		</li>
		<li>
			<label>Radionuclide half life ({{getUnit("SEC")}})</label>
			<span class="right-col">{{protocol.radionuclideHalfLife}}</span>
		</li>
		<li>
			<label>Radionuclide total dose ({{getUnit("MBQ")}})</label>
			<span class="right-col">{{protocol.radionuclideTotalDose}}</span>
		</li>
		<li>
			<label>Radiopharmaceutical code</label>
			<span class="right-col">{{protocol.radiopharmaceuticalCode}}</span>
		</li>
		<li>
			<label>Randoms correction method</label>
			<span class="right-col">{{protocol.randomsCorrectionMethod}}</span>
		</li>
		<li>
			<label>Reconstruction method</label>
			<span class="right-col">{{protocol.reconstructionMethod}}</span>
		</li>
		<li>
			<label>Rescale slope</label>
			<span class="right-col">{{protocol.rescaleSlope}}</span>
		</li>
		<li>
			<label>Rescale type</label>
			<span class="right-col">{{protocol.rescaleType}}</span>
		</li>
		<li>
			<label>Scatter correction method</label>
			<span class="right-col">{{protocol.scatterCorrectionMethod}}</span>
		</li>
		<li>
			<label>Scatter fraction factor</label>
			<span class="right-col">{{protocol.scatterFractionFactor}}</span>
		</li>
		<li>
			<label>Units</label>
			<span class="right-col">{{protocol.units}}</span>
		</li>
		<li>
			<label>Voxel size X ({{getUnit("MM")}})</label>
			<span class="right-col">{{protocol.voxelSizeX}}</span>
		</li>
		<li>
			<label>Voxel size Y ({{getUnit("MM")}})</label>
			<span class="right-col">{{protocol.voxelSizeY}}</span>
		</li>
		<li>
			<label>Voxel size Z ({{getUnit("MM")}})</label>
			<span class="right-col">{{protocol.voxelSizeZ}}</span>
		</li>
	</ol>
</fieldset>
