<!--
Shanoir NG - Import, manage and share neuroimaging data
Copyright (C) 2009-2019 Inria - https://www.inria.fr/
Contact us on https://project.inria.fr/shanoir/

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

You should have received a copy of the GNU General Public License
along with this program. If not, see https://www.gnu.org/licenses/gpl-3.0.html
-->


<table *ngIf="challengeStudy"><tr>
    <td class="left-cell">
        <div class="block-title">
            <span class="challenge-title">
                <i class="fas fa-trophy"></i>
                Challenge
                <i class="fas fa-trophy"></i>
            </span>
        </div>
        <p class="challenge-name">{{challengeStudy.name}}</p>
        <p class="msg">Here you can now download all the ressources you need for the challenge and explore its data using Shanoir</p>
    </td>
    <td>
        <ul class="challenge-files">
            <div [hidden]="!downloadState?.progress">
                <progress-bar [progress]="downloadState?.progress"  [text]="'Preparing download'" [unknownDownload]="true"></progress-bar>
            </div>
            <li *ngFor="let filePath of challengeStudy.protocolFilePaths">
                <i class="fas fa-download" (click)="downloadFile(filePath)"></i>
                <span (click)="downloadFile(filePath)">{{filePath}}</span>
            </li>
            <li class="study">
                <i class="fa-regular fa-folder-open" [routerLink]="'/study/details/' + challengeStudy.id"></i>
                <span [routerLink]="'/study/details/' + challengeStudy.id">Explore the study</span>
            </li>
        </ul>
    </td>
</tr></table>