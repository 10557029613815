<!--
Shanoir NG - Import, manage and share neuroimaging data
Copyright (C) 2009-2019 Inria - https://www.inria.fr/
Contact us on https://project.inria.fr/shanoir/

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

You should have received a copy of the GNU General Public License
along with this program. If not, see https://www.gnu.org/licenses/gpl-3.0.html
-->

<node
        *ngIf="node"
        [class.selected]="this.menuOpened || treeService.isSelected(node.id, 'center')"
        [label]="node.label"
        awesome="fa-regular fa-hospital"
        (firstOpen)="loadEquipments(); loadCoils()"
        [(opened)]="node.opened"
        (labelClick)="menuOpened = withMenu && !menuOpened"
        [route]="node.route"
        [hasChildren]="hasChildren()"
        [title]="node.title + ' n°' + node.id">

    <dropdown-menu [(openInput)]="menuOpened" *ngIf="menuOpened && withMenu && withMenu">
        <a [routerLink]="this.detailsPath + this.node.id" class="open-new-tab">
            <menu-item label="Details..." awesome="fa-regular fa-eye"></menu-item>
        </a>
    </dropdown-menu>

    <ng-container *ngIf="node.open && node.acquisitionEquipments && node.acquisitionEquipments != $any('UNLOADED')">
        <equipment-node
                node
                *ngFor="let acquisitionEquipment of node.acquisitionEquipments; let i = index"
                [input]="acquisitionEquipment"
                (onEquipmentDelete)="onEquipmentDelete(i)"
                (onNodeSelect)="onEquipementNodeSelect.emit($event)"
                [withMenu]="withMenu">
        </equipment-node>
    </ng-container>
    <ng-container *ngIf="node.open && node.coils && node.coils != $any('UNLOADED')">
        <coil-node
                node
                *ngFor="let coil of node.coils; let i = index"
                [input]="coil">
        </coil-node>
    </ng-container>
</node>
