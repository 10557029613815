<!--
Shanoir NG - Import, manage and share neuroimaging data
Copyright (C) 2009-2019 Inria - https://www.inria.fr/
Contact us on https://project.inria.fr/shanoir/

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

You should have received a copy of the GNU General Public License
along with this program. If not, see https://www.gnu.org/licenses/gpl-3.0.html
-->

<div class="header command-zone">1. Select BRUKER archive</div>
<fieldset class="step dicom">
    <p>Please select a BRUKER zip archive to import.</p>
    <p>When uploaded, Bruker archive will be automatically converted to DICOM.</p>
    <p><strong class="warning"><i class="fas fa-exclamation-triangle"></i> Important notice :</strong> this archive must contain at least one 2dseq file!</p>
    <upload-file (fileChange)="uploadArchive($event)" [loading]="archiveStatus == 'uploading'" [error]="archiveStatus == 'error'"></upload-file>
    <label *ngIf="extensionError==true" class="form-validation-alert" i18n="Import|ExtensionError label">
        A Bruker zip archive is required!
    </label>
    <label *ngIf="dicomDirMissingError==true" class="form-validation-alert" i18n="Import|DicomDirMissingError label">
        DICOMDIR is missing in .zip file!
    </label>  
    <br/>
    <ul class="progress" [ngSwitch]="uploadProgress">
        <li *ngIf="uploadProgress >= 1"><i class="fas fa-angle-right"></i>
            Uploading bruker archive
            <span *ngIf="uploadProgress == 1"><i class="fa fa-cog fa-spin"></i></span>
            <span class="done" [class.error]="archiveStatus == 'error'">
                <span *ngIf="uploadProgress >= 3"><i class="fas fa-check"></i></span>
                <span *ngIf="uploadProgress == 2"><i class="fas fa-times"></i> error !</span>
            </span>
        </li>
        
        <li *ngIf="uploadProgress >= 1"><i class="fas fa-angle-right"></i>
            Converting to Dicom
            <span *ngIf="uploadProgress == 3"><i class="fa fa-cog fa-spin"></i></span>
            <span class="done" [class.error]="archiveStatus == 'error'">
                <span *ngIf="uploadProgress >= 5"><i class="fas fa-check"></i></span>
                <span *ngIf="uploadProgress == 4"><i class="fas fa-times"></i> error !</span>
            </span>
        </li>
    </ul>
        
    <p *ngIf="modality" class="modality">
        The modality of the dataset(s) that you are importing is
        <span *ngIf="modality=='MR'">MRI</span>
        <span *ngIf="modality=='PET'">PET</span>
    </p>
</fieldset>
<div>
    <checkbox [disabled]="uploadProgress < 5" (onChange)="storeArchiveChanged($event)"></checkbox>
    <label i18n="Store bruker archive|Store bruker archive label@@StoreBrukerArchive"> Store bruker archive </label>
</div>
<button class="next" [disabled]="!valid" (click)="next()">Next</button>
