<!--
Shanoir NG - Import, manage and share neuroimaging data
Copyright (C) 2009-2019 Inria - https://www.inria.fr/
Contact us on https://project.inria.fr/shanoir/

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

You should have received a copy of the GNU General Public License
along with this program. If not, see https://www.gnu.org/licenses/gpl-3.0.html
-->
<p>Caution: Shanoir offers a web-based configuration possibility to auto-generate a BIDS-compliant tree,
    based on the meta-data available, see BIDS data type. Very often a special, study-specific mapping configuration
    is necessary to generate a fully-compliant BIDS tree (see undefined folders). This configuration needs to be done
    by a study expert on using the study cards, that are applied during the import or afterwards to complete this mapping.</p>
<ng-template #recursiveList let-datas let-id="id">
    <node *ngFor="let item of datas; let i = index" 
        [label]="getFileName(item.path)" 
        [deploy]="false"
        [awesome]="(!item.file) ? 'far fa-folder' : 'fas fa-brain'"
        (labelClick)="getContent(item, id + i)"
        [buttonPicto]="hasDownloadRights() ? 'fas fa-save' : undefined"
        (buttonClick)="download(item)"
        (openClick)="getDetail($event)"
        [hasChildren]="item.elements && item.elements.length > 0"
        [class.selected]="selectedIndex == id + i">
      <ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: item.elements, id: id + i }"></ng-container>
    </node>
</ng-template>

<node label="BIDS" awesome="far fa-folder" class="root-node" (firstOpen)="getBidsStructure()" [dataLoading]="load=='loading'" [hasChildren]="list ? (list.length > 0 ? true : false) : 'unknown' "
        [buttonPicto]="'fas fa-refresh'"
        (buttonClick)="refresh()">
    <ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: list, id: 'ID' }"></ng-container>
</node>

<div *ngIf="json" class="content">
    <b> {{title}} </b> <br/>
    <ngx-json-viewer [json]="json"></ngx-json-viewer>
</div>
<div *ngIf="tsv" class="content">
    <b> {{title}} </b> <br/>
    <table>
        <tr *ngFor="let line of tsv">
            <td *ngFor="let col of line; let i=index" [class.first]="i == 0" [class.last]="i == line.length - 1">
                {{col}}
            </td>
        </tr>
    </table>
</div>
