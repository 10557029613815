<!--
Shanoir NG - Import, manage and share neuroimaging data
Copyright (C) 2009-2019 Inria - https://www.inria.fr/
Contact us on https://project.inria.fr/shanoir/

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

You should have received a copy of the GNU General Public License
along with this program. If not, see https://www.gnu.org/licenses/gpl-3.0.html
-->
<button type="button" (click)="resetSearch()" title="clear search"><i class="fas fa-broom"></i></button>
<input class="search-txt" [(ngModel)]="filter.searchStr" (change)="search()" type="text" placeholder="search..."/>
to filter
<select class="search-field" [(ngModel)]="filter.searchField" (change)="search()">
    <option *ngIf="searchableColumns?.length > 1" value="">*</option>
    <option *ngFor="let col of searchableColumns" value="{{col.field}}">
        {{col.headerName}}
    </option>
</select>
