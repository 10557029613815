<!--
Shanoir NG - Import, manage and share neuroimaging data
Copyright (C) 2009-2019 Inria - https://www.inria.fr/
Contact us on https://project.inria.fr/shanoir/

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

You should have received a copy of the GNU General Public License
along with this program. If not, see https://www.gnu.org/licenses/gpl-3.0.html
-->
<div [hidden]="!downloadState?.progress">
    <progress-bar [progress]="downloadState?.progress"  [text]="'Preparing download'" [unknownDownload]="true"></progress-bar>
</div>
<node
        *ngIf="node"
        [class.selected]="this.menuOpened || treeService.isSelected(node.id, 'examination')"
        [clickable] = "this.node.id != null"
        [label]="node.label"
        awesome="fas fa-stethoscope"
        [(opened)]="node.opened"
        (labelClick)="menuOpened = withMenu && !menuOpened"
        [route]="node.route"
        [hasBox]="hasBox"
        [(ngModel)]="node.selected"
        (chkbxChange)="selectedChange.emit()"
        (firstOpen)="firstOpen()"
        [hasChildren]="hasChildren()"
        [title]="node.title + ' n°' + node.id">

    <dropdown-menu [(openInput)]="menuOpened" *ngIf="menuOpened && withMenu && this.node.id != null">
        <a [routerLink]="this.detailsPath + this.node.id" class="open-new-tab">
          <menu-item label="Details..." awesome="fa-regular fa-eye"></menu-item>
        </a>
        <menu-item label="View DICOM" awesome="fa-regular fa-eye" (click)="viewExaminationDicoms()"></menu-item>
        <menu-item [disabled]="(datasetIds && datasetIds.length == 0) || downloading" label="Download" awesome="fas fa-download" (click)="download()"></menu-item>
        <menu-item *ngIf="node.canDelete"  label="Delete" awesome="fa-regular fa-trash-can" (click)="deleteExamination()"></menu-item>
    </dropdown-menu>

    <ng-container *ngIf="node.open && node.datasetAcquisitions && node.datasetAcquisitions != $any('UNLOADED')">
        <dataset-acquisition-node
                node
                *ngFor="let datasetAcqNode of node.datasetAcquisitions; let i = index"
                [input]="datasetAcqNode"
                (selectedChange)="selectedChange.emit()"
                [hasBox]="hasBox"
                (onAcquisitionDelete)="onAcquisitionDelete(i)"
                [withMenu]="withMenu"
        >
        </dataset-acquisition-node>
    </ng-container>

    <node
            *ngIf="!!node.extraDataFilePathList && node.extraDataFilePathList != $any('UNLOADED') && node.extraDataFilePathList.length > 0"
            label="Extra data files"
            awesome="fa-regular fa-folder-open"
            [hasChildren]="!!node.extraDataFilePathList && node.extraDataFilePathList != $any('UNLOADED') && node.extraDataFilePathList.length > 0"
            [(opened)]="node.extraDataOpen">
        <node
                *ngFor="let file of node.extraDataFilePathList"
                label="{{file}}"
                awesome="fas fa-file"
                [buttonPicto]="'fas fa-download'"
                (buttonClick)="downloadFile(file)"
                [hasChildren]="false">
        </node>
    </node>
</node>
